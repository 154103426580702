import React, {Component} from "react";
import { motion } from 'framer-motion';

class Contact extends Component {
    render() {

        return (
            <section
                id="contact"
                className="flex justify-center items-center"
            >
                <div className="lg:max-w-screen-2xl  w-full justify-center items-center hidden lg:flex ">
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        className="h-full flex justify-end  mt-10 lg:mt-0  w-4/12	px-24 "

                    >
                        <div className="  ">
                            <div className="flex flex-col justify-center items-left "
                                 style={{height: 450}}>
                                <div className="">
                                    <div className="flex ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={2} stroke="white" className="size-12  mr-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                        </svg>
                                        <h2 className="">
                                            Horaires
                                        </h2>
                                    </div>
                                    <div>
                                        <p>Lundi 09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Mardi  09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Jeudi 09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Vendredi 09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Samedi  09:30 - 12:30 / 13h30 - 19h30 </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        className="lg:w-1/2 flex w-full p-4 "
                    >
                        <div className="w-full">
                            <iframe
                                width="100%"
                                height="450"
                                style={{border: 0}}
                                loading="lazy"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyANLRNgPEwJMx152X-WDej1hLHtdhnJcHQ&q=9+rue+fontenelle+60157+Elincourt+sainte+marguerite"
                                title="Google Map"
                            ></iframe>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}

                        transition={{duration: 0.5}}
                        className=" h-full flex justify-start w-4/12 px-24 "

                    >
                        <div className="">
                            <div className="flex flex-col justify-center items-left "
                                 style={{height: 450}}>

                                <div className="flex flex-col ">
                                    <div className="flex ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={2} stroke="white" className="size-12 mb-4 mr-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"/>
                                        </svg>
                                        <h2 className="">
                                            Adresse
                                        </h2>
                                    </div>

                                    <div>
                                      <p className="text-center">Cyril Caudroy</p>
                                        <p className="text-center ">Praticien en soin énergétique</p>
                                        <p className="text-center">9 rue Fontenelle </p>
                                        <p className="text-center">60157 Elincourt Sainte Marguerite</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </motion.div>
                </div>
                <div className=" w-full justify-center pt-20 items-center flex flex-col lg:hidden ">
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        className="w-full flex"

                    >
                        <div className="lg:w-1/2 flex w-full p-4 ">
                            <iframe
                                width="100%"
                                height="450"
                                style={{border: 0}}
                                loading="lazy"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyANLRNgPEwJMx152X-WDej1hLHtdhnJcHQ&q=9+rue+fontenelle+60157+Elincourt+sainte+marguerite"
                                title="Google Map"
                            ></iframe>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        className="w-full flex"

                    >
                        <div className=" h-full flex justify-center w-full">
                            <div className="flex flex-col justify-center items-left w-80	"
                            >
                                <div className="flex flex-col  justify-center">
                                    <div className="flex justify-center items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={2} stroke="white" className="size-12 mb-12 mr-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"/>
                                        </svg>
                                        <h2 className="">
                                            Adresse
                                        </h2>
                                    </div>

                                    <div>
                                        <p className="text-center">Cyril Caudroy</p>
                                        <p className="text-center ">Praticien en soin énergétique</p>
                                        <p className="text-center">9 rue Fontenelle </p>
                                        <p className="text-center">60157 Elincourt Sainte Marguerite</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        className="w-full flex"

                    >
                        <div className=" h-full flex justify-center w-full my-20">
                            <div className="flex flex-col justify-center"
                            >
                                <div className="w-80	">
                                    <div className="flex justify-center items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={2} stroke="white" className="size-12 mb-12 mr-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                        </svg>
                                        <h2 className="mb-3 justify-center">
                                            Horaires
                                        </h2>
                                    </div>
                                    <div>
                                     <p>Lundi 09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Mardi  09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Jeudi 09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Vendredi 09:30 - 12:30 / 13h30 - 19h30 </p>
                                        <p>Samedi  09:30 - 12:30 / 13h30 - 19h30 </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </section>
        )
            ;
    }
}

export default Contact;
